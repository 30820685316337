<template>
  <HeaderComponent :header-class="[headerClass, { hidden: !showHeader }]" />
  <main>
    <RouterView />
  </main>
</template>

<script>
import { RouterView } from 'vue-router'
import { langMixin } from '@/lang.js'
import HeaderComponent from '@/components/Header/Header.vue'
import { computed } from 'vue'

export default {
  name: 'RootApp',
  components: { HeaderComponent, RouterView },
  mixins: [langMixin('Common')],
  provide() {
    return {
      currentUser: computed(() => this.currentUser),
      setCurrentUser: newValue => {
        this.currentUser = newValue
      }
    }
  },
  data() {
    return {
      SDKLoaded: false,
      currentUser: null,
      showHeader: true
    }
  },
  computed: {
    headerClass() {
      return ['/', '/contact'].includes(this.$route.path) ? 'header-transparent' : ''
    },
    tabTitle() {
      const routeName = this.$route?.name || 'home'
      const pageTitle = this.t.PageTitle?.[routeName]
      const appTitle = this.t.appTitle || 'AAM'
      return [pageTitle, appTitle].filter(x => x).join(' · ')
    }
  },
  watch: {
    tabTitle() {
      this.$nextTick(() => {
        document.title = this.tabTitle
      })
    }
  },
  created() {
    window.seezSdk?.getCurrentUser().then(r => (this.currentUser = r))
  },
  mounted() {
    this.loadCookiebot()
    window.addEventListener('scroll', this.handleScroll)
    this.calculateViewHeight()
    window.addEventListener('resize', this.calculateViewHeight)
    this.loadSDK()
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    async loadSDK() {
      const components = ['listing-details','search', 'favorites', 'saved-searches', 'carousel', 'trade-in', 'orders']
      const promises = components.map(c => window.customElements.whenDefined('seez-sdk-' + c))
      await Promise.allSettled(promises)
      window.seezSdk.getCurrentUser().then(user => (this.user = user))
      this.SDKLoaded = true
    },
    calculateViewHeight() {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    },
    login() {
      window.seezSdk.showLogin(null, true, {
        branding: '<div class="branding">&nbsp;</div>',
        terms: this.languageResources?.Search?.termsPolicy,
        marketing: this.languageResources?.Profile?.marketingTerms
      })
    },
    //converts a string into something healthy for urls
    slugify(text) {
      return text
        ?.trim()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase()
        .replace(/[^\w]+/g, '-')
        .replace(/-+$/g, '')
        .toLowerCase()
    },
    handleScroll() {
      this.showHeader = window.scrollY < 50
    },
    loadCookiebot() {
      if (import.meta.env.VITE_COOKIEBOT_ID == null || import.meta.env.VITE_ENV_LABEL === 'local') return

      const script = document.createElement('script')
      script.id = 'Cookiebot'
      script.src = 'https://consent.cookiebot.com/uc.js'
      script.dataset.cbid = import.meta.env.VITE_COOKIEBOT_ID
      script.dataset.blockingmode = 'auto'
      script.type = 'text/javascript'
      script.async = true
      script.setAttribute('data-culture', this.language)

      document.head.appendChild(script)
    }
  }
}
</script>

<style lang="scss">
#app {
  .header-transparent {
    background-color: transparent;
    transition: 0.3s background-color;

    &[data-display-mobile-menu='true'] {
      background-color: var(--accent);
    }

    box-shadow: none;

    .aamLogo path {
      fill: var(--background);
    }

    .aamLogoMobile svg * {
      fill: var(--background);
    }

    nav a svg * {
      stroke: var(--background);
    }

    nav a {
      color: var(--background);
    }

    .language-btn span {
      color: var(--background);
    }

    .user-dropdown-btn {
      border: 2px solid var(--background);

      > svg * {
        fill: var(--background);
      }
    }

    .mobile-burger-btn svg * {
      stroke: var(--background);
    }

    .mobile-burger-btn svg.close * {
      fill: var(--background);
    }
  }

  > footer {
    grid-area: footer;
    padding: 1rem;
    color: var(--background);
    display: grid;
    grid-template-columns: 1fr auto auto;
    gap: 2rem;

    > .footerLogo {
      fill: var(--background);
      height: 1.5em;
    }

    > a {
      text-decoration: none;
      color: var(--background);
    }

    background-color: var(--dark-background);
  }
}

seez-sdk-login-form {
  .seezLogin {
    --highlight: var(--primary);
    --base-font: var(--base-font);

    @media screen and (min-width: 50rem) {
      margin: -1rem;
    }

    .loginForm {
      @media screen and (max-width: 30rem) {
        padding: 0;
      }

      .otp > .inputOtpWrapper > .inputCode {
        @media screen and (max-width: 30rem) {
          gap: 0;
        }
      }
      .otp > h1 {
        white-space: unset;
      }
      input,
      button,
      > .socialWrapper > div > button.social {
        border-radius: 0.75rem;
      }

      h1 {
        font-family: var(--heading-font);
      }
      button {
        color: var(--accent);
        font-size: 1rem;
        font-weight: 600;
      }

      .socialWrapper {
        h5 {
          font-size: 1rem;
          font-weight: 500;
          color: var(--neutral-50);
          text-align: center;
        }
      }
    }

    .loginForm > .checkboxWrapper label {
      display: flex;
      align-items: flex-start;
      text-align: start;

      input {
        min-width: 1rem;
      }
    }

    .error {
      display: none;
    }
  }

  .branding {
    background-color: var(--accent);
    background-image: url('/assets/aam-logo.svg'), url('/assets/vector.svg');
    background-repeat: no-repeat, no-repeat;
    background-position:
      50% 10%,
      center right;
    background-size: 82%, contain;
    border-radius: 0;
    width: 18rem;
    height: 100%;
  }

  [slot='terms'] {
    display: inline;

    a {
      color: black;
      font-weight: bold;
      text-decoration: underline;
    }
  }

  [slot='marketing'] {
    display: inline;
  }
}

seez-sdk-logout .logoutModal {
  --base-font: var(--base-font);
  > button.main,
  > button:not(.main) {
    border-radius: 0.5rem;
  }
}
</style>
