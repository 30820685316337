<template>
  <section class="featured-cars vertical-padding">
    <h2 class="title">{{ t.featuredCars }}</h2>
    <seez-sdk-carousel to="/listing/{id}" :filters="filters" :lg="language" @click-payment="clickPayment">
      <div slot="financingTerms">
        <p class="financingTerms">{{ languageResources?.Common?.financingTerms }}</p>
      </div>
    </seez-sdk-carousel>
  </section>
</template>

<script>
import { langMixin } from '@/lang'

export default {
  name: 'FeaturedCars',
  mixins: [langMixin('Home')],
  computed:{
    filters(){
      return `sort=-attractiveness&models=${import.meta.env.VITE_FEATURE_MODELS_ID}`
    }
  },
  methods: {
    clickPayment(e) {
      const listing = e.detail[0].listing
      window.location = `/listing/${listing?.id}#calculator`
    }
  }
}
</script>
<style lang="scss">
@import '@/assets/styles/base.scss';

section.featured-cars {
  padding-inline-start: var(--content-side-padding);

  > h2 {
    margin-bottom: 2rem;
  }
}

seez-sdk-carousel.carousel {
  --highlight: var(--primary);
  --accented-background: #ddf8f4;

  & > .listings {
    grid-area: 1/1;
  }

  > button.prev,
  > button.next {
    grid-area: 2/1;
    place-self: start;
    width: 2.625rem;
    height: 2.625rem;
    background-color: var(--accent);

    > svg {
      width: 1.1rem;
      height: 1.1rem;
      stroke: var(--background);
    }
  }

  > button.next {
    margin-inline-start: 5rem;
  }

  > button.prev {
    margin-inline-end: 5rem;
  }

  .listingCard {
    @include listingCard();
  }
}

seez-sdk-carousel.carousel > .listings > * {
  min-width: max(20em, calc(100% / 3.8));
}
seez-sdk-carousel.carousel > button {
  --border-color: var(--dark-background);

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}
</style>
