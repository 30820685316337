const currencyCorrections = { dk: 'DKK', kr: 'DKK', 'kr.': 'DKK', $: 'USD', '€': 'EUR' } //this is temporal until the backend sends the proper currency code
// const iso4217 = new Set(Intl.supportedValuesOf('currency'))

const languageFetchPromises = {}

// promise unifier to avoid multiple fetch
async function fetchLanguageFile(language) {
  if (languageFetchPromises[language] == null) languageFetchPromises[language] = fetch(`${import.meta.env.VITE_MARKET_PLACE}/cms/${language}/aam`).then(r => r.json())
  return languageFetchPromises[language]
}

export const langMixin = compName => ({
  props: { lg: { type: String, default: null } }, //lang is a reserved attribute name
  data() {
    return {
      languageAsset: null
    }
  },
  computed: {
    language() {
      //scales components tree
      let c = this
      while (c != null) {
        if (c.lg) return c.lg
        c = c.$parent
      }

      //scale tags tree
      let t = this.$el ?? document.body
      while (t != null) {
        if (t.getAttribute?.('lang')) return t.getAttribute('lang')
        t = t.parentElement
      }
      return navigator.language?.substring(0, 2) ?? 'ar'
    },
    languageResources() {
      if (this.languageAsset == null) {
        let l = this.language.substring(0, 2)
        if (!['en', 'ar'].includes(l)) {
          console.warn(`Language resources for ${l} not found. Falling back to english`)
          l = 'ar'
        }
        fetchLanguageFile(l).then(r => {
          this.languageAsset = r
        })
      }
      return this.languageAsset
    },
    textDirection() {
      // const locale = new Intl.Locale(this.language)
      return this.language === 'ar' ? 'rtl' : 'ltr'
    },
    t() {
      return this.languageResources?.[compName] ?? {}
    }
  },
  created() {
    // Forcing prefetch of the language resources to avoid issues with lazy loading
    this.languageResources
  },
  methods: {
    getCurrency(price, fallbackCurrency) {
      const cc = price.currency ?? fallbackCurrency
      const result = currencyCorrections[cc] ?? cc
      return result || null
    },
    formatPrice(price, fallbackCurrency) {
      const currency = this.getCurrency(price, fallbackCurrency)
      if (currency == null) return null
      return this.formatNumber(price.value, 0, currency)
    },
    formatNumber(number, decimals, currency) {
      if (number == null) return ''
      const options = {}
      if (decimals != null) Object.assign(options, { minimumFractionDigits: decimals, maximumFractionDigits: decimals })
      if (currency != null) Object.assign(options, { style: 'currency', currency, currencyDisplay: 'narrowSymbol' })
      return new Intl.NumberFormat(this.language, options).format(parseFloat(number))
    },
    formatDate(date) {
      const unixTime = typeof date === 'number' ? date : Date.parse(date)
      if (isNaN(unixTime)) return null
      return new Intl.DateTimeFormat(this.language).format(unixTime)
    }
  }
})
